export default function MenuConfig(){

    return(


        [
            {
                "menuId": 1,
                "menuName": "Transmittal Report",
                "menuIcon": "fas fas fa-layer-group",
                "className": "nav-item",
                "isOpen": true,
                "displayOrder": 0,
                "subMenus": [
                    {
                        "menuId": 1,
                        "subMenuId": 2,
                        "subMenuName": "Export Report",
                        "urlText": "/exportreport",
                        "className": "nav-item",
                        "displayOrder": 1
                    }
                ]
            }
        ]
    );

}