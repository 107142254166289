import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Menu from "../Menu/menu";
import Header from "../Header/header";
import useAppContext from "../../Hooks/useAppContext";
import Loading from "../../components/Loader/loader";


const Layout = (props) => {
  let appSetting = useAppContext();


  return (

    <div className={appSetting.isApplicationMenuOpen ? 'wrapper ' : 'wrapper sidebar_minimize'}>
       {appSetting.loading && <Loading />}
       <Header  startPage={props.startPage}/>
      <div className="main-panel">
        <div id="childPlaceHolder" className="content">
          <div className="page-inner">
            {/* {appSetting.isAuthenticated ? <Outlet /> : <MicrosoftLoginPage/>} */}
            <Outlet />
            {/* <Loading /> */}
          </div>
        </div>
      </div>
      {appSetting.isAuthenticated && <Menu startPage={props.startPage}  applicationMenus={appSetting.applicationMenus} />}
    </div>
  );
}

export default Layout;