 


String.isNullOrEmpty = function (value) {
    try {
        return (!value || typeof (value) === 'Object' || value === null || value === undefined || value.trim() === "" || value.length === 0);
    } catch (e) {
        return false;
    }
}






String.getInitials = function (value) {
    try {
        let fullName = []
        fullName = value.split([',']);

        if (fullName.length === 0)
            fullName = value.split([' ']);

        let initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
        return initials.toUpperCase();
    }
    catch {
        return value;
    }
}

const defaultDateFormat = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
}

Date.format = function (value,format) {
    return new Intl.DateTimeFormat('en-US', defaultDateFormat).format(value);
}

function precise_round(num, decimals) {
    return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

String.prototype.toCamelCase = function () {
    return this.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2, offset) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
    });
};


String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};