import React from "react";

import "../Loader/style.css";
import  logo from "../../Assets/Images/loader.png"
const Loading = () => {
    return (
        <>
            <div aria-hidden="true" className="mainDiv" style={{ opacity: "1", transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms" }}>
                <span className="inside_div">
                    <div><span className="Loading__spinner" style={{ zIndex: "999" }}>
                    </span>
                        <img className="loaderLogo h-50px" src={logo} alt="WSP" /> </div>
                   </span>
            </div>
        </>
    );
};
export default Loading;
