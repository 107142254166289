import React, { useEffect, useState, useLayoutEffect } from "react";
import Unauthorized from "./Pages/Unauthorized/unauthorized";
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './Layouts/Main/layout';
import DynamicComponent from "./Helpers/dynamicComponent";
import useAppContext from "./Hooks/useAppContext";
import Loading from "./components/Loader/loader";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import AuthenticatedUserLayout from "./Layouts/Main/authenticatedUserLayout";
import UnAuthenticatedUserLayout from "./Layouts/Main/unauthenticatedUserLayout";
import { ToastContainer } from "react-toastify";
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Helpers/unit.js';

function App(props) {






    return (
        <>

         <ToastContainer></ToastContainer>
            <AuthenticatedTemplate>
                <AuthenticatedUserLayout startPage={'/exportreport'} />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <UnAuthenticatedUserLayout startPage={props.startPage} />
            </UnauthenticatedTemplate>
        </>
    );


}

export default App;
