import React from 'react';
import logo from "../../Assets/Images/logo.png";
import UseAppContext from '../../Hooks/useAppContext';
import { Popup, Header } from 'semantic-ui-react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';





const ApplicationHeader = (props) => {

  const { instance } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const appSetting = UseAppContext();

  const onMenuIconClick = () => {

    appSetting.showApplicationMenu(!appSetting.isApplicationMenuOpen);
  }


  const onLogoutClick = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }



  return (

    <div className="main-header">

      <div className={isAuthenticated ? 'logo-header' : 'logo-header unauthenticated-logo-header'} data-background-color="wsp" >

        <a href={props.startPage} className="logo">
          <img src={logo} alt='navbar brand' className='navbar-brand' />
          <span className='applicationName navbar-toggler'>  Transmittal Report </span>

        </a>

        {isAuthenticated ?
          <button onClick={onMenuIconClick} className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
              <i className='icon-menu font-weight-bold'></i>
            </span>
          </button>
          : <div />}
        {isAuthenticated ?
          <div className="nav-toggle">
            <button onClick={onMenuIconClick} type='button' className="btn btn-toggle toggle-sidebar">
              <i className='icon-menu font-weight-bold'></i>

            </button>
          </div>
          : <div />}
      </div>



      <nav className="navbar navbar-header text-center" data-background-color="red2">

        {



          <img src={logo} alt='navbar brand' className={appSetting.isApplicationMenuOpen ? 'min-logo d-none' : 'min-logo'} />

        }

        <span className='applicationName'>  Transmittal Report </span>

        {isAuthenticated ?
          <div className='text-right col' style={{ minWidth: '250px' }}>

            <Popup size='mini'
              content={
                <Header subheader={appSetting.user.email} content={appSetting.user.employeeName} />
              }

              hideOnScroll
              position='top left'
              trigger={
                <div className="avatar-sm avatar avatar-online rounded-0 mr-2">
                  <img src={appSetting.user.imageUrl} alt="..." className="avatar-img avatar-circle" />
                </div>
              }
            />

            <Popup size='mini' style={{ cursor: 'pointer' }}
              content={<Header content={'Logout'} />}
              hideOnScroll
              position='top left'
              trigger={
                <button type='button' className="btn btn-toggle btn-boader  btn-logout toggle-sidebar" onClick={onLogoutClick}>
                  <i className="fas fa-power-off"></i>
                </button>
              }
            />
          </div> : <div />

        }
      </nav>
    </div>
  )

}

export default ApplicationHeader;