import React, { useEffect } from 'react';
import ExportReport from '../Pages/ExportReport/exportReport';
import UseAppContext from '../Hooks/useAppContext';
import Unauthorized from '../Pages/Unauthorized/unauthorized';
import { useLocation } from 'react-router-dom';

const DynamicComponent = (props) => {

 
    // let appSetting = UseAppContext();
    const location = useLocation();
    
    useEffect(() => {
        
       

    },[location])



    switch (props.path) {
        case '/exportreport':
           return <ExportReport />;
           case '/':
            return <ExportReport />;
        default: 
            return <Unauthorized />;

    }
}

export default DynamicComponent;