 
import axios from "axios";
import autodesklogin from '../../Assets/Images/autodesk.png';
import { useEffect, useState } from "react";
import useAppContext from "../../Hooks/useAppContext";
import AutodeskLogin from "../autodeskLogin";
import ExportTransmittalReport from "./exportTransmittalReport";


export default function ExportReport(props) {

    const [isAutodeskAuthenticatedUser, setIsAutodeskAuthenticatedUser] = useState(false);
    const appSetting = useAppContext();

    const getAutodeskAuthenticationDetails = async () => {

        await axios
            .get(`/AutodeskAuth/GetUserDetails`).then((res) => {
                
                setIsAutodeskAuthenticatedUser(res.data.isAuthenticatedUser);
            })
            .catch(error => {
                console.log(error.response.data);
            });
    }


    useEffect(() => {
        appSetting.showLoading(false);
        const fetchAutodeskAuthenticationDetails = async () => {
            await getAutodeskAuthenticationDetails();
        }
        fetchAutodeskAuthenticationDetails();
    },[]);


    return (
        isAutodeskAuthenticatedUser ? <ExportTransmittalReport/> :
            <AutodeskLogin />
    );
}