import './Assets/Styles/bootstrap.min.css';
import './Assets/Styles/site.css';
import './Assets/Styles/custom.css';
import './Assets/Styles/fonts.css';
import 'semantic-ui-css/semantic.css';
import { AppContextProvider } from './Context/appContextProvider';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const msalInstance = new PublicClientApplication(msalConfig);
 

ReactDOM.render(

    <BrowserRouter basename={baseUrl}>
    <AppContextProvider>

      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>

    </AppContextProvider>
  </BrowserRouter>
  ,
  rootElement);

  registerServiceWorker();

