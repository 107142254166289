import React from "react";
import { useNavigate } from "react-router-dom";


const Unauthorized = () => {


    const navigate = useNavigate();

    const goBack = () => { navigate('/exportreport') };


    return (


        <div className="card">
            <div className="card-header">
                <div className="card-title">Unauthorized</div>



            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-1 text-center">

                        <i  className="flaticon-error text-danger unauthorized-Page-Icon"></i>

                    </div>
                    <div className="col-md-11 ">

                        <h4 className="card-title text-danger">Invalid Url Request</h4>

                        <span  style={{cursor:'pointer'}} className="text-primary text-decoration-underline "  onClick={goBack}>
                            Back to Review
                        </span>


                    </div>
                </div>
            </div>
        </div>

    )

}

export default Unauthorized
