import axios from "axios";
import autodeskLoginImg from '../Assets/Images/autodesk.png';


export default function AutodeskLogin() {
    const handleAutodeskLogin = async () => {

        await axios
            .get(`/AutodeskAuth/GetAuthonicationURL`).then((res) => {
                const json = res.data;
                window.location.href = json.urlString;
            })
            .catch(error => {
                console.log(error.response.data);
            });
    }


    return (
        <div className="text-center autodeskLoginBtn">
            <button className="btn mt-5 btn-lg button-ipad " onClick={handleAutodeskLogin}>
                <img src={autodeskLoginImg} />
                <b> Sign In</b>
            </button>
        </div>
    );

}