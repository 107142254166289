import React from "react";
import mslogo from '../Assets/Images/ms.png';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

export default function MicrosoftLoginPage() {

    const { instance } = useMsal();
    React.useEffect(() => {
        instance.loginRedirect(loginRequest).catch(e => {
            
        });
    }, []);


    return (
        <div className="text-center mt-5">

            <button class="btn btn-primary btn-border fw-bold ">
                <img className='mr-2' src={mslogo}></img>
                Sign in with Microsoft</button>
        </div>
    )
}