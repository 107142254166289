import React, { useEffect, useState } from "react";
import FolderTree, { testData } from "react-folder-tree";
import 'react-folder-tree/dist/style.css';
import axios from "axios";
import useAppContext from "../../Hooks/useAppContext";
import {toast} from 'react-toastify';
import { Cookies } from "react-cookie";


export default function ExportTransmittalReport() {

    const [authorizedProjectDetails, setauthorizedProjectDetails] = useState([]);
    const [gtcString, setGtcString] = useState('');
    const [projectHeaderDetails, setProjectHeaderDetails] = useState([]);
    const [treeState, setTreeState] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedFolderContents, setSelectedFolderContents] = useState([]);
    const [disabledCreactButton, setDisabledCreactButton] = useState(true);
    const [disabledSaveButton, setdisabledSaveButton] = useState(true);
    const [selectedFile, setSelectedFile] = useState('');
    const imageInputRef = React.useRef();
    const selectedImageRef = React.useRef();
    const reportTypeRef = React.useRef();


    const appSetting = useAppContext();
    const [projectHeaderDetail, setProjectHeaderDetail] = useState({
        toAddress: '',
        ccAddress: '',
        designation: '',
        gtcString: '',
        projectName: '',
        refNo: '',
        gtcProjectName: ''
    });

    const onTreeStateChange = (state, event) => console.log(state, event);


    const handleProjectChange = async (e) => {

        setProjectHeaderDetail({
            toAddress: '',
            ccAddress: '',
            designation: '',
            gtcString: '',
            projectName: '',
            refNo: '',
            reviewer: '',
            gtcProjectName: ''
        });

        imageInputRef.current.value = '';
        appSetting.showLoading(true);
        setTreeState([]);
        let _projectDetails = authorizedProjectDetails.filter(s => { return s.projectId == e.target.value });
        setSelectedProject(_projectDetails[0]);

        await axios
            .get(`DocumentManagement/GetAllProjectFolders?selectedProjectId=${_projectDetails[0].projectId}&hubId=${_projectDetails[0].hubId}`).then((response) => {
                setTreeState(response.data[0]);
                setDisabledCreactButton(true);
            })
            .catch(error => {
                handleError(error);
            }).finally(() => {

                appSetting.showLoading(false);
            });


    }

 

    const onFileChange = (event) => {
        setdisabledSaveButton(false);
        setSelectedFile(event.target.files[0]);
        if (event.target.files.length > 0)
            selectedImageRef.current.value = event.target.files[0].name;
        else
            selectedImageRef.current.value = '';
    }

    const onFileBrowse = () => {
        imageInputRef.current.click();
    }


    const handleGetAllAuthorizedProjects = async () => {
        appSetting.showLoading(true);
        await axios
            .get('DocumentManagement/GetAllAuthorizedProjects').then((response) => {

                setauthorizedProjectDetails(response.data.projectDetail);
                setProjectHeaderDetails(response.data.headerDatas);

                if (response.data.projectDetail.length > 0)
                    setSelectedProject(response.data.projectDetail[0]);


                if (response.data.folderTreeViews.length > 0)
                    setTreeState(response.data.folderTreeViews[0])

            })
            .catch(error => {
                handleError(error);
            }).finally(() => {
                appSetting.showLoading(false);
            });
    }








    let folderContentDetails = [];
    const handleTreeNodeClick = async (node) => {


        setDisabledCreactButton(true);
        setdisabledSaveButton(true);


        setSelectedFolder(node.nodeData);
        setSelectedFolderContents([]);



        let indexOfGTC = -1;
        let selectedGTCString = '';



        if (node.nodeData.path.length > 2) {

            const _treeState = JSON.parse(JSON.stringify(treeState));

            var gtcNode = _treeState.children[node.nodeData.path[0]];
            var _gtcNode = gtcNode.children[node.nodeData.path[1]];

            indexOfGTC = 0;
            setGtcString(_gtcNode.name);
            selectedGTCString = _gtcNode.name;
        }
        else {

            indexOfGTC = node.nodeData.name.search('GTC');
            setGtcString(node.nodeData.name);
            selectedGTCString = node.nodeData.name;
        }

        if (indexOfGTC > -1) {


            let _tempHeaderDetails = projectHeaderDetails.filter(s => { return s.projectName === selectedProject.projectId && s.refNo === selectedGTCString });
            if (_tempHeaderDetails.length > 0) {


                let s = {
                    toAddress: _tempHeaderDetails[0].toAddress,
                    ccAddress: _tempHeaderDetails[0].ccAddress,
                    designation: _tempHeaderDetails[0].designation,
                    gtcString: _tempHeaderDetails[0].gtcString,
                    projectName: selectedProject.projectId,
                    refNo: selectedGTCString,
                    reviewer: _tempHeaderDetails[0].reviewer,
                    gtcProjectName: _tempHeaderDetails[0].gtcProjectName
                }
                setProjectHeaderDetail(s);
            }
            else {

                setProjectHeaderDetail({
                    toAddress: '',
                    ccAddress: '',
                    designation: '',
                    gtcString: '',
                    projectName: '',
                    refNo: '',
                    reviewer: '',
                    gtcProjectName: ''
                });
            }

            imageInputRef.current.value = '';
            selectedImageRef.current.value='';
        }
        else {

            setProjectHeaderDetail({
                toAddress: '',
                ccAddress: '',
                designation: '',
                gtcString: '',
                projectName: '',
                refNo: '',
                reviewer: '',
                gtcProjectName: ''

            });
            imageInputRef.current.value = '';
            selectedImageRef.current.value='';



        }


        appSetting.showLoading(true);

        await axios
            .get(`DocumentManagement/GetAllProjectFolderContents?selectedProjectId=${selectedProject.projectId}&selectedFolderId=${node.nodeData.id}&folderName=${node.nodeData.name}`).then((response) => {

                folderContentDetails = response.data;
                setSelectedFolderContents(folderContentDetails.folderFileDetail);

                if (folderContentDetails.folderFileDetail.length > 0)
                    setDisabledCreactButton(false);
                else
                    setDisabledCreactButton(true);

                const _treeState = JSON.parse(JSON.stringify(treeState));
                let _tempEvalString = '';
                node.nodeData.path.forEach((element, index) => {

                    if (index == 0)
                        _tempEvalString = '_treeState.children[' + element + ']';
                    else
                        _tempEvalString += '.children[' + element + ']';
                });

                if (_tempEvalString == '')
                    _treeState.children = folderContentDetails.folderSubFolderDetail;
                else
                    eval(_tempEvalString).children = folderContentDetails.folderSubFolderDetail;

                setTreeState(_treeState);

            })
            .catch(error => {
                handleError(error);
            }).finally(() => {
                appSetting.showLoading(false);
            });


    }



    useEffect(() => {
        const fetchAuthorizedProjects = () => {
            handleGetAllAuthorizedProjects();
        }
        fetchAuthorizedProjects();
    }, []);


    const handleCreateReport = async () => {

        appSetting.showLoading(true);   

      
        var toAddress = projectHeaderDetail.toAddress;
        var ccAddress = projectHeaderDetail.ccAddress;
        var designation = projectHeaderDetail.designation;
        var projectName = selectedProject.projectName;
        var _gtcString = projectHeaderDetail.gtcString;
        var _reportTypeRef = reportTypeRef.current.value;
        var refNo = gtcString;
        var fileType = "pdf";

        var _fileName = selectedFolder.name;

        if (selectedFolder.name != null) {
            _fileName = selectedFolder.name.replace("DTS", "DTA");
            var _temp = _fileName.split("-");

            if (_temp.length > 2)
                _fileName = _fileName.replace(_temp[1], "WSP");
        }


        let url = `DocumentManagement/CreateTransmittalReports?issueContainerId=${selectedProject.issueContainerId}&selectedProjectId=${selectedProject.projectId}&selectedFolderId=${selectedFolder.id}&toAddress=${toAddress}&ccAddress=${ccAddress}&designation=${designation}&projectName=${projectName}&gtcString=${_gtcString}&refNo=${refNo}&fileType=${fileType}&reportTypeRef=${_reportTypeRef}&folderName=${selectedFolder.name}`;





        await axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', _fileName + '.pdf');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {


            if (error.response.status == 406) {

           

                toast.error('0 Issues Found In Selected Folder.', {
                    position: toast.POSITION.TOP_RIGHT,
                    onClose:()=>{
    
                    }
                  });

            }
            else {
                handleError(error);
            }





        }).finally(()=>{

            appSetting.showLoading(false);   
        });



        appSetting.showLoading(true);   

        fileType = "excel";
        url = `DocumentManagement/CreateTransmittalReports?issueContainerId=${selectedProject.issueContainerId}&selectedProjectId=${selectedProject.projectId}&selectedFolderId=${selectedFolder.id}&toAddress=${toAddress}&ccAddress=${ccAddress}&designation=${designation}&projectName=${projectName}&gtcString=${_gtcString}&refNo=${refNo}&fileType=${fileType}&reportTypeRef=${_reportTypeRef}&folderName=${selectedFolder.name}`;


        await axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', _fileName + '.xls');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {

            if (error.response.status == 406) {

                // toast.error('0 Issues Found In Selected Folder.', {
                //     position: toast.POSITION.TOP_LEFT,
                //     onClose:()=>{
    
                //     }
                //   });

            }
            else {
                handleError(error);
            }

        }).finally(()=>{
            appSetting.showLoading(false);   
        });


     
    }




    const inputEvent = (event) => {




        const value = event.target.value;
        const name = event.target.name;

        var indexOfGTC = gtcString.indexOf('GTC');

        if (indexOfGTC > -1) {
            setDisabledCreactButton(true);
            setdisabledSaveButton(false);
        }

        setProjectHeaderDetail((preValue) => {

            if (name === 'toAddress') {
                return {
                    toAddress: value,
                    ccAddress: preValue.ccAddress,
                    designation: preValue.designation,
                    gtcString: preValue.gtcString,
                    projectName: selectedProject.projectId,
                    reviewer: preValue.reviewer,
                    refNo: gtcString,
                    gtcProjectName: preValue.gtcProjectName,
                };
            }
            if (name === 'ccAddress') {
                return {
                    toAddress: preValue.toAddress,
                    ccAddress: value,
                    designation: preValue.designation,
                    gtcString: preValue.gtcString,
                    projectName: selectedProject.projectId,
                    reviewer: preValue.reviewer,
                    gtcProjectName: preValue.gtcProjectName,
                    refNo: gtcString
                };
            }

            if (name === 'designation') {
                return {
                    toAddress: preValue.toAddress,
                    ccAddress: preValue.ccAddress,
                    designation: value,
                    gtcString: preValue.gtcString,
                    projectName: selectedProject.projectId,
                    reviewer: preValue.reviewer,
                    gtcProjectName: preValue.gtcProjectName,
                    refNo: gtcString
                };
            }

            if (name === 'gtcString') {
                return {
                    toAddress: preValue.toAddress,
                    ccAddress: preValue.ccAddress,
                    designation: preValue.designation,
                    gtcString: value,
                    projectName: selectedProject.projectId,
                    reviewer: preValue.reviewer,
                    gtcProjectName: preValue.gtcProjectName,
                    refNo: gtcString
                };
            }


            if (name === 'reviewer') {
                return {
                    toAddress: preValue.toAddress,
                    ccAddress: preValue.ccAddress,
                    designation: preValue.designation,
                    gtcString: preValue.gtcString,
                    projectName: selectedProject.projectId,
                    gtcProjectName: preValue.gtcProjectName,
                    reviewer: value,
                    refNo: gtcString
                };
            }

            if (name === 'gtcProjectName') {
                return {
                    toAddress: preValue.toAddress,
                    ccAddress: preValue.ccAddress,
                    designation: preValue.designation,
                    gtcString: preValue.gtcString,
                    projectName: selectedProject.projectId,
                    gtcProjectName: value,
                    reviewer: preValue.reviewer,
                    refNo: gtcString
                };
            }
        });

    }



    const handleSaveHeaderDetail = async () => {

       appSetting.showLoading(true);

        let formData = new FormData();
        formData.append('toAddress', projectHeaderDetail.toAddress);
        formData.append('ccAddress', projectHeaderDetail.ccAddress);
        formData.append('designation', projectHeaderDetail.designation);
        formData.append('gtcString', projectHeaderDetail.gtcString);
        formData.append('projectName', projectHeaderDetail.projectName);
        formData.append('refNo', projectHeaderDetail.refNo);
        formData.append('reviewer', projectHeaderDetail.reviewer);
        formData.append('gtcProjectName', projectHeaderDetail.gtcProjectName);
        formData.append('signature', selectedFile);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        await axios.post('DocumentManagement/SaveHeaderDetail', formData, config)
            .then(response => {
                toast.error('Data Saved Successfully.', {
                    position: toast.POSITION.TOP_RIGHT,
                    onClose:()=>{
    
                    }
                  });

                if (selectedFolderContents.length > 0)
                    setDisabledCreactButton(false);
            })
            .catch(error => {
                handleError(error);
            }).finally(()=>{
                appSetting.showLoading(false);
                
            });

        await axios
            .get(`DocumentManagement/GetProjectDetails`).then((response) => {

                setProjectHeaderDetails(response.data);
            })
            .catch(error => {
                handleError(error);
            });

    }




    const handleError = (error) => {

       
        if (error.response.status == 404 || error.response.data == "Invalid Session") {

            toast.error('Session Expired.', {
                position: toast.POSITION.TOP_RIGHT,
                onClose:()=>{
                    const forgeApp = Cookies.get('ForgeApp');

                    if (forgeApp != undefined) {
                        Cookies.remove('ForgeApp');
                       // Auth.logout(() => {
                            window.location.href = '/'
                       // });
                    }
                }
              });
        }
        else {

            toast.error(error.response.data, {
                position: toast.POSITION.TOP_RIGHT,
                onClose:()=>{

                }
              });
        }

        console.log(error.response.data);
    }


    const downloadHelpDocument = async () => {

        let url = `DocumentManagement/GetHelpFile`;


        await axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Transmittal Report Help Document.pdf');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {

            handleError(error);
        });


    }



    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        
                        <i className="fas fa-file-alt"></i>
                        Export Report

                        <i style={{float:'right',fontSize:'20px',marginTop:'1px',cursor:'pointer'}} className="fas fa-info-circle" onClick={downloadHelpDocument} ></i>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">


                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label fw-bold  "> <h4> Select Project </h4></label>
                                        <select className="form-control  requiredField" onChange={e => handleProjectChange(e)}>
                                            {authorizedProjectDetails.map((o) => <option key={o.projectId} value={o.projectId}>{o.projectName}</option>)}
                                        </select>



                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label ">To Address</label>
                                        <textarea type="text" rows="3" className="form-control  requiredField" name="toAddress" value={projectHeaderDetail.toAddress} onChange={inputEvent} />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label ">CC Address</label>
                                        <textarea type="text" rows="3" className="form-control  requiredField" name="ccAddress" value={projectHeaderDetail.ccAddress} onChange={inputEvent} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label ">Designation</label>
                                        <textarea type="text" rows="3" className="form-control  requiredField" name="designation" value={projectHeaderDetail.designation} onChange={inputEvent} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label ">GTC Number</label>
                                        <input type="text" className="form-control  requiredField" name="gtcString" value={projectHeaderDetail.gtcString} onChange={inputEvent} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label ">GTC Name</label>
                                        <textarea type="text" rows="3" className="form-control requiredField" name="gtcProjectName" value={projectHeaderDetail.gtcProjectName} onChange={inputEvent} />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label ">Reviewer  Initials</label>
                                        <input type="text" className="form-control  requiredField" name="reviewer" value={projectHeaderDetail.reviewer} onChange={inputEvent} />

                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label d-block ">Originator's Signature</label>

                                        <div className="input-group">

                                            <input type="text" ref={selectedImageRef} placeholder="Selected file...." className="form-control" readOnly={true} />
                                            <div className="input-group-prepend">
                                                <button type="submit" className="btn btn-default" onClick={onFileBrowse}>
                                                    <b>  Browse</b>
                                                </button>
                                            </div>
                                        </div>

                                        <input type="file" className="form-control d-none" ref={imageInputRef} accept="image/png" name="pmSignature" onChange={onFileChange.bind(this)} />
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-1">

                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label"><h4> Browse Folder <b style={{ fontSize: '12px' }} className="text-primary ">(Click on folder name to load sub-folder)</b> </h4></label>
                                        <FolderTree data={treeState}
                                            onNameClick={handleTreeNodeClick} onChange={onTreeStateChange} showCheckbox={false} readOnly={true} initOpenStatus='Custom' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer ">
                    <button type="button" className="btn btn-info btn-sm  " onClick={handleSaveHeaderDetail}  >Save Changes</button>
                </div>

            </div>
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        
                        {selectedFolder !== null ? <i className="fas fa-folder-open"></i> : ''}
                        {selectedFolder !== null ? selectedFolder.name : ''}
                    </div>
                </div>
                <div className="card-body">

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="table-responsive ">
                                <table className="table table-bordered table-head-bg-info table-bordered-bd-info">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>File Name</th>
                                            <th>Created By</th>
                                            <th>Created On</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {selectedFolderContents.map((o, index) =>

                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{o.displayName}</td>
                                                <td>{o.createdBy}</td>
                                                <td>{o.createdOn}</td>

                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                            <div className="form-group">
                                <label className="form-label d-block">Report Type</label>
                                <select className="form-control form-control-sm requiredField" ref={reportTypeRef} name="reportType">
                                    <option> DRAFT</option>
                                    <option> FINAL</option>
                                </select>


                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="card-footer ">
                    <button onClick={handleCreateReport.bind(this)} className="btn btn-default btn-sm " disabled={disabledCreactButton} > Create Report</button>
                </div>

            </div>
        </>
    );
};


