import React,{ createContext, useState } from "react";  
 

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {


  const [loading, setLoading] = useState(false);
  const [isApplicationMenuOpen, setApplicationMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [applicationMenus, setApplicationMenus] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [userClaims, setUserClaims] = useState([]);
  const [user, setUser] = useState({
    imageUrl: '',
    employeeName: '',
    loginId: '',
    email: '',
    roles: [],
  });
  const showLoading = (value) => {
    setLoading(value);
  }

  const showApplicationMenu = (value) => {
    setApplicationMenuOpen(value);

  }

  const clearNotifyAll = () => {
    // toast.dismiss();
  }




  const notify = (opt) => {
    // toast.dismiss();


    // let _opt = {
    //   position: toast.POSITION.TOP_RIGHT,
    //   autoClose: false,
    //   type: toast.TYPE.ERROR,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,

    // }

    // if (opt.type == 'success')
    //   _opt.type = toast.TYPE.SUCCESS


    // _notify(opt.message, _opt)
  };

  // const _notify = (message, opt) => toast(message,
  //   opt);





  let defaultErrorMessage = 'An error occurred while processing your request.';
  const processError = (opt) => {
    debugger;

    let error = opt.error;
    if (error.response.status == 401) {
      notify({ message: 'Unauthorized Access.' });
    }
    else if (error.response.status == 403) {
      notify({ message: 'Unauthorized Access.' });
    }
    else {
      if (typeof error.response.data === 'object') {
        if (error.response.data.hasOwnProperty('displayMessage')) {
          notify({ message: error.response.data.displayMessage ? error.response.data.displayMessage : defaultErrorMessage });
        }
        else
          notify({ message: defaultErrorMessage });
      }
      else
        notify({ message: defaultErrorMessage });
    }
  }

  const appSetting = {
    isApplicationMenuOpen,
    setApplicationMenuOpen,
    loading,
    showLoading,
    isAuthenticated,
    setIsAuthenticated,
    user,
    setUser,
    applicationMenus,
    showApplicationMenu,
    setApplicationMenus,
    notify,
    clearNotifyAll,
    processError,
    userRoles,
    setUserRoles,
    userClaims,
    setUserClaims
  }





  const [auth, setAuth] = useState({});

  return (
    <AppContext.Provider value={appSetting}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContext;