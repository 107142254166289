import Layout from "./layout";
import useAppContext from "../../Hooks/useAppContext";
import  {Routes,Route,Navigate} from 'react-router-dom';
import MicrosoftLoginPage from "../../Pages/microsoftLogin";
 




export default function UnAuthenticatedUserLayout(props) {
const appSetting=useAppContext();
 


    return (
        <div className={appSetting.isApplicationMenuOpen ? 'App nav_open ' : 'App'}>

            <Routes>
                <Route path="/" element={<Layout startPage={props.startPage} />}>
                    <Route  path="/"  element={<MicrosoftLoginPage/>}></Route>
                    <Route  path="/*" element={<Navigate to="/" />}></Route>
                </Route>
            </Routes>

        </div>
    )
}