import React, { useEffect, useState, useLayoutEffect } from "react";
import Unauthorized from "../../Pages/Unauthorized/unauthorized";
import { Routes, Route, Navigate, Router } from 'react-router-dom';
import Layout from "./layout";
import DynamicComponent from "../../Helpers/dynamicComponent";
import useAppContext from "../../Hooks/useAppContext";
import Loading from "../../components/Loader/loader";
import MenuConfig from "../../menuConfig";
import { useMsal } from "@azure/msal-react";
import { loginRequest, graphConfig } from "../../authConfig";
import unknownImage from '../../Assets/Images/UserUnknown.png';


export default function AuthenticatedUserLayout(props) {


    const { instance, accounts } = useMsal();
    const [isApplicationMenusLoaded, setIsApplicationMenusLoaded] = useState(false);
    let appSetting = useAppContext();

    const onLayoutMount = async () => {

        onComponentMount();
    }

    const onComponentMount = async () => {

        appSetting.setApplicationMenus(MenuConfig);

        if (accounts.length > 0) {
            const currentAccounts = accounts[0];

            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then(async (response) => {
                    let resp = await fetch(`${graphConfig.graphMeEndpoint}${'/photos/120x120/$value'}`, {
                        headers: { authorization: `bearer ${response.accessToken}` }
                    })
                    if (resp) {
                        let blob = await resp.blob()
                        const _imageUrl= URL.createObjectURL(blob);
                        appSetting.setUser((prevState) => {
                            return ({
                                ...prevState,
                                imageUrl: _imageUrl,
                            })
                        });
                    }
                });
             
            appSetting.setUser((prevState) => {
                return ({
                    ...prevState,
                    imageUrl: unknownImage,
                    loginId: currentAccounts.username,
                    employeeName: currentAccounts.name,
                    email: currentAccounts.username,
                })
            });

            appSetting.setIsAuthenticated(true);
            setIsApplicationMenusLoaded(true);
        }

    }


    useLayoutEffect(() => {
        onLayoutMount();
    }, []);

    useEffect(() => {


    }, [])



    if (!isApplicationMenusLoaded)
        return (<div className='App'> <Loading /> </div>);
 

    return (

        <div className={appSetting.isApplicationMenuOpen ? 'App nav_open ' : 'App'}>
   

            <Routes>
                <Route basename='#/' path="/" element={<Layout startPage={props.startPage} />}>
                    <Route exact path="/unauthorized" element={<Unauthorized />}></Route>
                    {
                        ([].concat.apply([], appSetting.applicationMenus.map(s => s.subMenus))).map((item, index) =>
                            <Route key={item.subMenuId} exact path={item.urlText} element={<DynamicComponent startPage={props.startPage} path={item.urlText} />}></Route>
                        )
                    }
                    <Route exact path="/" element={<Navigate to={props.startPage} />}></Route>
                    <Route exact path="/*" element={<Navigate to={props.startPage} />}></Route>
                </Route>
            </Routes>

        </div>
    );


}


