import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Collapse } from 'react-bootstrap'
import UseAppContext from '../../Hooks/useAppContext'


export default function (props) {
    console.log('menu render');

    const appSetting = UseAppContext();
    const location = useLocation();


    let _applicationMenus = props.applicationMenus;

    const [applicationMenus, setApplicationMenus] = useState(_applicationMenus);

    const handleSubmenuClick = (s) => {


        // if (defaultMenuCloseSubMenuIds.indexOf(s.subMenuId) > -1)
        //     appSetting.setApplicationMenuOpen(false);
        // else
        //     appSetting.setApplicationMenuOpen(true);

        const applicationMenusString = JSON.stringify(applicationMenus);
        const tempApplicationMenus = JSON.parse(applicationMenusString);

        tempApplicationMenus.forEach(m => {
            m.className = 'nav-item ';
            m.isOpen = false;
            if (m.menuId === s.menuId) {
                m.className = 'nav-item active '
                m.isOpen = true;
            }

            m.subMenus.forEach(submenu => {

                submenu.className = 'nav-item';
                if (submenu.subMenuId === s.subMenuId)
                    submenu.className = 'nav-item active'

            });
        });
        setApplicationMenus(tempApplicationMenus);
    }


    const handleShowMenuClick = (m) => {

        const applicationMenusString = JSON.stringify(applicationMenus);
        const tempApplicationMenus = JSON.parse(applicationMenusString);

        tempApplicationMenus.forEach(menu => {

            if (menu.menuId === m.menuId)
                menu.isOpen = !menu.isOpen;

        });
        setApplicationMenus(tempApplicationMenus);
    }




    useEffect(() => {
        
        var _allSubMenus = [].concat.apply([], applicationMenus.map(s => s.subMenus));
        var selectedSubMenu = _allSubMenus.filter(s => s.urlText.toLowerCase() === location.pathname.toLowerCase());
        if (selectedSubMenu.length > 0)
            handleSubmenuClick(selectedSubMenu[0]);

    }, [location])

    return (
        <div className="sidebar sidebar-style-2">
            <div className="sidebar-wrapper scrollbar scrollbar-inner" style={{ overflow: 'hidden' }}>
                <div className="sidebar-content">
                    <div className="user">
                        <div className="avatar-sm avatar avatar-online float-left mr-2">
                            <img src={appSetting.user.imageUrl} alt="..." className="avatar-img avatar-circle" />
                        </div>
                        <div className="info">
                            <a data-toggle="collapse" aria-expanded="true">
                                <span>
                                    {appSetting.user.employeeName}
                                    <span className="user-level"> {appSetting.user.email}</span>

                                </span>
                            </a>
                            <div className="clearfix"></div>

                        </div>
                    </div>
                    <div className="nav nav-primary">
                        {


                            applicationMenus.map((menu, i) =>

                                <div key={i} className={menu.className} >
                                    <a onClick={handleShowMenuClick.bind(this, menu)} aria-controls={i} aria-expanded={menu.isOpen} >
                                        <i className={menu.menuIcon}></i>

                                        <p >{menu.menuName} </p>

                                        <span className="caret"></span>
                                    </a>
                                    <Collapse in={menu.isOpen}>
                                        <div id={i}>
                                            <ul className="nav nav-collapse">
                                                {menu.subMenus.map((subMenu, j) =>
                                                    <li key={j} onClick={handleSubmenuClick.bind(this, subMenu)} className={subMenu.className}>
                                                        <Link key={subMenu.subMenuId} to={subMenu.urlText}>
                                                            <span className="sub-item">{subMenu.subMenuName}</span>
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </Collapse>
                                </div>
                            )

                        }

                    </div>
                </div>
            </div>
        </div>
    )

}